import React from 'react'
import Helmet from 'react-helmet';

const CustomHelmet = ({ page, image }) => (
    <Helmet>
        <title>{page.title}</title>
        <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, shrink-to-fit=no, viewport-fit=cover"
        />
        <meta charset="UTF-8" />
        <meta name="description" content={page.description} />
        <meta name="robots" content="index,follow" />

        <link rel="canonical" href={page.siteUrl} />
        <html lang="de-DE" />
    </Helmet>
)

export default CustomHelmet