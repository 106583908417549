import React from 'react'
import Layout from '../components/indexLayout'
import { graphql } from 'gatsby'
import CustomHelmet from '../components/CustomHelmet';
import GatsbyConfig from '../../gatsby-config';
import MyGallery from '../components/MyGallery';

/*
Eigentliche Page für die einzelnen Immobilien.
TODO: Umzubennenen in ImmoTemplate!
*/
export default function ImmoTemplate({ data }) {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <div className="page white-bg">
        <CustomHelmet page={{
          title: `${post.frontmatter.title}`,
          siteUrl: `${GatsbyConfig.siteMetadata.link}${post.frontmatter.slug}`,
          image: `${post.frontmatter.cover.publicURL}`
        }}
          image={post.frontmatter.cover.publicURL}
        />
        <div className="container">
          <div className="blog-page">

            {
              /**
               * 
               * Blog page section
               */
            }
            <div
              data-aos="fade-up"
              className="blog-contents"
            >

              {
                /**
                 * Meta area
                 */
              }
              <p className="tags"> {post.frontmatter.tags}</p>
              <h1 className="title"> {post.frontmatter.title} </h1>
              {
                <p className="description"> {post.frontmatter.description} </p>
              }
              {
              //  <p className="description"> {post.frontmatter.slug} </p>
              }
              <div className="dash" />

              {
                /**
                 * Cover image
                 */
              }
              <p>
                {
                  //<MyGallery myimages={buildGalleryArray(data.allFile.edges)}/>
                }
                <MyGallery myimages={buildGalleryArray2(post.frontmatter.images)}/>
              </p>

              {
                /**
                 * Immobiliendetails - Teil 1
                 */
              }
              <h2 className="title"> Immobilienüberblick </h2>

              <div className="immo-details-wrapper">

                <div className="immo-details-template">
                  <p className="immo-details-name"><b>{post.frontmatter.price}</b></p>
                  <p className="immo-details-name">Kaufpreis</p>
                </div>

                <div className="immo-details-template">
                  <p className="immo-details-name"><b>{post.frontmatter.number_rooms}</b></p>
                  <p className="immo-details-name">Zimmer</p>
                </div>

                <div className="immo-details-template">
                  <p className="immo-details-name"><b>{post.frontmatter.living_space}</b></p>
                  <p className="immo-details-name">Wohnfläche</p>
                </div>

                {
                  /*
                  Grundstücksfläche ist optional, da nur für Häuser sinnvoll.
                  Kann aber auch bei anderen Immobilien gesezt werden.
                  Wenn vorhanden wir das angezeigt, wenn nicht vorhaden dann nicht.
                  */
                }
                {
                  post.frontmatter.plot_size ? (
                    <div className="immo-details-template">
                      <p className="immo-details-name"><b>{post.frontmatter.plot_size}</b></p>
                      <p className="immo-details-name">Grundstücksfläche</p>
                    </div>
                  )
                  : null
                }

              </div>
              <div className="dash_small" />

              {
                /**
                 * Ende vom "statischen Teil", die jede Immobilie hat.
                 * Jetzt kommt das dynamische Frontmatter und der Seitencontent.
                 */
              }
              <h2 className="title"> Immobiliendetails </h2>
              {
                //TODO: Wie übergebe ich das Array richtig? Warum muss in der Funktion nochmal auf data zugegriffen werden?
                //<PrintImmoDetailsTwoRows data={post.frontmatter.immo_details} />
              }
              {
                //Alternative Versuch mit <dl>
                <PrintImmoDetailsWithDl data={post.frontmatter.immo_details} />
              }
              <div className="dash_small" />

              {
                /**
                 * Immobiliendetails - Teil 2
                 */
              }

              <h2 className="title"> Energieausweis </h2>
              {
                //TODO: Wie übergebe ich das Array richtig? Warum muss in der Funktion nochmal auf data zugegriffen werden?
                //<PrintImmoDetailsTwoRows data={post.frontmatter.energie_details} />
              }
              {
                //Alternative Versuch mit <dl>
                <PrintImmoDetailsWithDl data={post.frontmatter.energie_details} />
              }
              <div className="dash_small" />

              {
                /**
                 * Contents
                 */
              }
              <div
                dangerouslySetInnerHTML={{ __html: post.html }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

function PrintImmoDetailsWithDl(immo_details_only) {
  return (
    <dl className="immo-details-wrapper3" role="presentation">
      {
        immo_details_only.data.map((immo_entry) =>
        <div className="immo-details-template3-div">
          <dt className="immo-details-template3-dt"> {immo_entry.immo_type} </dt>
          <dd className="immo-details-template3-dd">{immo_entry.immo_value}</dd>
        </div>
        )
      }
    </dl>
  )
}

function buildGalleryArray2(query_images) {
  var returnImageArray = [];
  query_images.map((image, index) =>

    returnImageArray.push({
      original: image.childImageSharp.fluid.src,
      thumbnail: image.childImageSharp.fixed.src,
      description: image.childImageSharp.fluid.originalName.substring(
        //0, image.node.childImageSharp.fluid.originalName.lastIndexOf('.')).match(/[\u00C0-\u017Fa-zA-Z\s]+/g),
        image.childImageSharp.fluid.originalName.indexOf('_') + 1, image.childImageSharp.fluid.originalName.lastIndexOf('.')),
    },)
  )
  //console.log("Mapped Array!")
  //console.log(returnImageArray)

  return returnImageArray
}

/*function buildGalleryArray(query_images) {
  var returnImageArray = [];
  query_images.map((image, index) =>

    returnImageArray.push({
      original: image.node.childImageSharp.fluid.src,
      thumbnail: image.node.childImageSharp.fixed.src,
      description: image.node.childImageSharp.fluid.originalName.substring(
        //0, image.node.childImageSharp.fluid.originalName.lastIndexOf('.')).match(/[\u00C0-\u017Fa-zA-Z\s]+/g),
        image.node.childImageSharp.fluid.originalName.indexOf('_') + 1, image.node.childImageSharp.fluid.originalName.lastIndexOf('.')),
    },)
  )
  //console.log("Mapped Array!")
  //console.log(returnImageArray)

  return returnImageArray
}*/

//Wird erstmal nicht bemötigt, aber evtl. später?
/*function PrintImmoDetailsTwoRows(immo_details_only) {
  //console.log("PrintImmoDetailsTwoRows->data: " + immo_details_only.data + " with length of: " + immo_details_only.data.length)
  //console.log(immo_details_only.data)
  var split_value = Math.ceil(immo_details_only.data.length/2)
  //console.log("Split-Value: " + split_value)
  var div_left = immo_details_only.data.slice(0, split_value)
  var div_right = immo_details_only.data.slice(split_value, immo_details_only.data.length)
  //console.log("PrintImmoDetailsTwoRows-> nach slice() div_left: " + div_left)
  //console.log(div_left)
  //console.log("PrintImmoDetailsTwoRows-> nach slice() div_right: " + div_right)
  //console.log(div_right)

  return (
    <div className="immo-details-wrapper2">
      <div className="immo-details-template2">
        {
          div_left.map((immo_entry) => <p> {immo_entry.immo_type}: <b>{immo_entry.immo_value}</b> </p> )
        }
      </div>
      <div className="immo-details-template2">
        {
          div_right.map((immo_entry) => <p> {immo_entry.immo_type}: <b>{immo_entry.immo_value}</b> </p> )
        }
      </div>
    </div>
  )
}*/

//von GraphQL entfernt
//author
//name
//date(formatString: "DD-MMM-YYYY")
//designation
export const postQuery = graphql`
  query blogPostQuery($path: String!) {
    markdownRemark(frontmatter: { slug: { eq: $path } }) {
      html
      frontmatter {
        cover {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        images {
          childImageSharp {
            fluid(maxWidth: 1920) {
              src
              originalName
            }
            fixed(width: 250) {
              src
            }
          }
        }
        slug
        tags
        title
        description
        price
        number_rooms
        living_space
        plot_size
        immo_details {
          immo_type
          immo_value
        }
        energie_details {
          immo_type
          immo_value
        }
      }
    }

    allFile(filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, relativeDirectory: { regex: "/^contents/blogs/.*/" }}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1920) {
              src
              originalName
            }
            fixed(width: 250) {
              src
            }
          }
        }
      }
    }
  }
`
//, { indexPage: "contents/blogs/2020-04-21--vogl-immobilien-house_1/gallery" })

// $slug == /blog/vogl-immobilien-haus_nrw_1
//allFile(filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, relativeDirectory: {eq: "contents/blogs/2020-04-21--vogl-immobilien-house_1/gallery"}}) {
//allFile(filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, relativeDirectory: { regex: "contents/blogs/"$path }}) {
//allFile(filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, relativeDirectory: { regex: "contents/$path." }}) {
//allFile(filter: {extension: {regex: "/(jpg)|(png)|(jpeg)/"}, relativeDirectory: { eq: "contents/blogs/2020-06-28-vogl-immobilien-haus_nrw_1/gallery" }}) {