import React from 'react';
import ImageGallery from 'react-image-gallery';

/*const images = [
  {
    original: 'https://picsum.photos/id/1018/1000/600/',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
    description: 'Description 1',
  },
  {
    original: 'https://picsum.photos/id/1015/1000/600/',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
    description: 'Description 2',
  },
  {
    original: 'https://picsum.photos/id/1019/1000/600/',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
    description: 'Description 3',
  },
];*/

class MyGallery extends React.Component {
  render() {
    return <ImageGallery 
      //items={images}
      items={this.props.myimages}
      showBullets='True'
      showIndex='True'
    />;
  }
}

export default MyGallery
